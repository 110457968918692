import type {MutableRef} from 'preact/hooks';

import {ShopIcon} from '~/components/icons/ShopIcon';
import {Modal} from '~/components/Modal/Modal';
import {useI18n} from '~/foundation/I18n/hooks';
import {useMonorail} from '~/foundation/Monorail/hooks';
import {useStoreMetadata} from '~/hooks/useStoreMetadata';
import {isMobileBrowser} from '~/utils/browser';
import {isoWindow} from '~/utils/window';

const DEV_MODE_QR_CODE_URL = 'https://shop.app/qr/sid/59659354134';

interface FollowingModalProps {
  anchorElement: MutableRef<HTMLButtonElement | null>;
  devMode: boolean;
  onClose: () => void;
  storefrontOrigin: string;
}

export function FollowingModal({
  anchorElement,
  devMode,
  onClose,
  storefrontOrigin,
}: FollowingModalProps) {
  const {trackUserAction} = useMonorail();
  const {translate} = useI18n();
  const {
    loaded: metaLoaded,
    storeId,
    storeName,
  } = useStoreMetadata(storefrontOrigin);

  const buttonLink = storeId ? `https://shop.app/sid/${storeId}` : '#';

  function handleContinueButtonClick() {
    trackUserAction({
      userAction: 'FOLLOWING_GET_SHOP_APP_CLICK',
    });
    isoWindow.open(buttonLink, '_self');
    onClose();
  }

  if (isMobileBrowser()) {
    const modalTitle = translate('shopFollowButton.followingModal.title', {
      store: storeName,
    });
    const modalSubtitle = translate('shopFollowButton.followingModal.subtitle');
    const modalButtonText = translate('shopFollowButton.continueGeneric');

    return (
      <Modal
        onDismiss={onClose}
        popupDisabled
        variant="follow"
        visible={metaLoaded}
      >
        <div className="m-auto p-6 font-sans">
          <div className="relative m-auto pb-4 text-center">
            <h2 className="mb-2 px-6 text-subtitle text-black">{modalTitle}</h2>
            <p className="overflow-hidden text-ellipsis whitespace-pre-line text-body-small text-grayscale-d1">
              {modalSubtitle}
            </p>
          </div>
          <div className="flex justify-center">
            <button
              className="w-full rounded-md bg-purple-primary p-3 leading-6 text-white no-underline"
              onClick={handleContinueButtonClick}
              type="button"
            >
              {modalButtonText}
            </button>
          </div>
        </div>
      </Modal>
    );
  }

  const qrCodeAltText =
    (translate('shopFollowButton.followingModal.qrAltText') as string) ?? '';
  let qrCodeUrl = storeId ? `https://shop.app/qr/sid/${storeId}` : `#`;
  if (devMode) {
    qrCodeUrl = DEV_MODE_QR_CODE_URL;
  }

  return (
    <Modal
      anchorTo={anchorElement}
      disableMinWidth
      hideHeader
      onDismiss={onClose}
      popupDisabled
      variant="follow"
      visible={metaLoaded}
    >
      <div className="m-auto w-55 p-6 font-sans">
        <div className="relative m-auto pb-4 text-center">
          <p className="overflow-hidden text-ellipsis whitespace-pre-line text-grayscale-d1">
            {translate('shopFollowButton.followingModal.qrHeader', {
              store: storeName,
            })}
          </p>
        </div>
        <div className="m-auto w-37 bg-white forced-color-adjust-none">
          <img src={qrCodeUrl} alt={qrCodeAltText} />
        </div>
        <div className="flex justify-center px-0 pb-0 pt-4 text-purple-primary">
          <ShopIcon className="h-5" />
        </div>
      </div>
    </Modal>
  );
}
