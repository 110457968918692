import {classNames} from '@shopify/css-utilities';

import type {IconProps} from './types';

interface HeartIconProps extends IconProps {
  filled?: boolean;
}

export function HeartIcon({className: classNameProp, filled}: HeartIconProps) {
  const className = classNames('text-inherit', classNameProp);
  const fill = filled ? 'currentColor' : 'none';

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill={fill}
        stroke="currentColor"
        stroke-linejoin="round"
        stroke-width="1.667"
        d="m9.14 4.502.86.881.86-.881a3.82 3.82 0 0 1 5.437-.064l.064.064c1.498 1.536 1.518 4.014.062 5.576l-.062.066L10 16.666l-6.36-6.524c-1.52-1.557-1.52-4.083 0-5.641a3.822 3.822 0 0 1 5.5 0Z"
      />
    </svg>
  );
}
