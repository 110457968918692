import {useCallback, useState} from 'preact/hooks';

import {getStorageItem, setStorageItem} from '~/utils/storage';

export const STORAGE_KEY = 'signInWithShop';

interface UseStorageConfig<TValueType> {
  key: string;
  type?: 'localStorage' | 'sessionStorage';
  defaultValue: TValueType;
}

export function useStorage<TValueType = unknown>({
  key,
  type = 'localStorage',
  defaultValue,
}: UseStorageConfig<TValueType>): [TValueType, (newValue: TValueType) => void] {
  const namespacedStorageKey = `${STORAGE_KEY}:${key}`;

  const [value, setValue] = useState<TValueType>(() => {
    const rawStorageValue = getStorageItem(namespacedStorageKey, {
      session: type === 'sessionStorage',
    });

    if (!rawStorageValue) {
      return defaultValue;
    }

    try {
      return JSON.parse(rawStorageValue);
    } catch (error) {
      return defaultValue;
    }
  });

  const setStoredValue = useCallback(
    (newValue: TValueType) => {
      setValue(newValue);

      setStorageItem(namespacedStorageKey, JSON.stringify(newValue), {
        session: type === 'sessionStorage',
      });
    },
    [namespacedStorageKey, type],
  );

  return [value, setStoredValue];
}
